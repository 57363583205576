import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  createStyles,
  Grid,
  StyleRules,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Image } from 'antd';
import imageCompression from 'browser-image-compression';
import { DropzoneDialogBase, FileObject } from 'material-ui-dropzone';

import { getCompanyGetVerification } from '../../actions/companyActions';
import { createCompanyAuthenticationInfo } from '../../model/companyAuthentication.model';
import { AppState } from '../../reducers';
import debug from '../../utils/debug';

const styles = (): StyleRules =>
  createStyles({
    box: {
      width: '800px',
      padding: '1rem 0',
      margin: '1rem auto',
      background: '#fff',
    },
    titleBox: {
      padding: '1rem 0',
      borderBottom: '1px solid rgb(64, 64, 64,0.3)',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      marginLeft: '0.5rem',
    },
    note: {
      fontSize: '0.75rem',
      color: '#242f65',
    },
    marginBox: {
      marginTop: '1rem',
    },
    agreement: {
      color: '#242f65',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    attachment: {
      marginBottom: '1rem',
    },
    image: {
      position: 'relative',
      width: '5rem',
      height: '5rem',
      marginRight: '1rem',
    },
    delIcon: {
      position: 'absolute',
      top: '-0.5rem',
      right: '-0.75rem',
      color: '#f81d22',
      cursor: 'pointer',
    },
  });

const InfoView = ({ classes }: Props): ReactElement => {
  const { control, getValues, trigger, setValue } = useForm({
    defaultValues: {
      companyAuthenticationInfo: createCompanyAuthenticationInfo(),
    },
    mode: 'onChange',
  });
  getValues;
  trigger;
  const { errors, isValid, isDirty } = useFormState({ control });
  isValid;
  isDirty;
  const { fields: companyAttachments, append, remove } = useFieldArray({
    control,
    name: 'companyAuthenticationInfo.companyAttachments',
  });

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  const company = useSelector((appState: AppState) => appState.company);

  const dispatch = useDispatch();

  const compressFile = async (fileToCompress: File): Promise<string> => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    let compressedFile: File;
    try {
      compressedFile = await imageCompression(fileToCompress, options);
      debug(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
    } catch (error) {
      debug('Compress file', error);
    }
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = (): void => resolve(reader.result as string);
      reader.readAsDataURL(compressedFile);
    });
  };

  const handleOnSave = async (fileObjects: FileObject[]): Promise<void> => {
    setIsUploadDialogOpen(false);
    debug('Handle on save', fileObjects);
    for (let index = 0; index < fileObjects.length; index++) {
      const element = fileObjects[index].file;
      const fileToUpload = await compressFile(element);
      append({ id: undefined, file: fileToUpload });
    }
  };

  const isRenderUpload = useMemo(() => {
    return companyAttachments.length < 3;
  }, [companyAttachments]);

  useEffect(() => {
    if (company.id && company.status) dispatch(getCompanyGetVerification(company.id));
  }, [company.id]);

  useEffect(() => {
    if (company?.verification?.basicInfo) {
      setValue('companyAuthenticationInfo', company.verification?.basicInfo);
    }
  }, [company?.verification?.basicInfo]);

  return (
    <Grid xs={12} className={classes.box} container>
      <Grid
        item
        xs={12}
        className={classes.titleBox}
        container
        justifyContent="center"
        alignItems="center"
      >
        <div className={classes.titleContainer}>
          <h2>
            <VerifiedUserIcon />
            <span className={classes.title}>企业认证</span>
          </h2>
          <div className={classes.note}>请务必填写真实企业信息，否则无法通过认证！</div>
        </div>
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs={8} container justifyContent="center">
          <Grid xs={8}>
            <Controller
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  id="LastName"
                  label="企业名称"
                  type="text"
                  fullWidth
                  disabled={
                    company?.verification?.status == 'SUBMITTED' ||
                    company?.verification?.status == 'VERIFIED'
                      ? true
                      : false
                  }
                  error={!!errors.companyAuthenticationInfo?.registrationName}
                  helperText={
                    !!errors.companyAuthenticationInfo?.registrationName && '企业名称不能为空'
                  }
                  {...field}
                />
              )}
              name="companyAuthenticationInfo.registrationName"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>
          </Grid>
          <Grid
            xs={8}
            justifyContent="space-between"
            alignItems="center"
            container
            className={classes.marginBox}
          >
            <Controller
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  id="LastName"
                  label="社会统一编码"
                  type="text"
                  style={{
                    width: isRenderUpload ? '80%' : '100%',
                  }}
                  disabled={
                    company?.verification?.status == 'SUBMITTED' ||
                    company?.verification?.status == 'VERIFIED'
                      ? true
                      : false
                  }
                  error={!!errors.companyAuthenticationInfo?.registrationId}
                  helperText={
                    !!errors.companyAuthenticationInfo?.registrationId && '社会统一编码不能为空'
                  }
                  {...field}
                />
              )}
              name="companyAuthenticationInfo.registrationId"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>

            {isRenderUpload ? (
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsUploadDialogOpen(true);
                }}
              >
                上传附件
              </Button>
            ) : null}
            <DropzoneDialogBase
              fileObjects={[]}
              open={isUploadDialogOpen}
              onAdd={(fileObjects): void => {
                handleOnSave(fileObjects);
              }}
              acceptedFiles={['.jpg,.jpeg,.bmp,.png']}
              showPreviews={true}
              maxFileSize={2097152}
              filesLimit={3}
              dropzoneText={t('certificate_input_view:drag_files')}
              dialogTitle={t('certificate_input_view:uploading')}
              submitButtonText={t('certificate_input_view:uploading')}
              cancelButtonText={t('certificate_input_view:cancellation')}
              onClose={(): void => {
                setIsUploadDialogOpen(false);
              }}
            />
          </Grid>
          <Grid
            xs={8}
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.marginBox}
          >
            <Typography
              variant="body2"
              align="left"
              color="secondary"
              className={classes.attachment}
            >
              附件：
              <br />
              &nbsp; 1.营业执照1张（支持jpg、jpeg、bmp、png格式，单个大小不超过2M）
              <br />
              &nbsp; 2.法人身份证正面、反面2张（支持jpg、jpeg、bmp、png格式，单个大小不超过2M）
            </Typography>
            <Grid item xs={8} container justifyContent="flex-start" alignItems="center">
              {companyAttachments.map((file, indexImg) => {
                return (
                  <Controller
                    key={file.id}
                    render={({ field }) => (
                      <div className={classes.image}>
                        <Image
                          src={field.value ? field.value[indexImg].file : ''}
                          width={80}
                          height={80}
                        />
                        <CancelIcon className={classes.delIcon} onClick={() => remove(indexImg)} />
                      </div>
                    )}
                    name="companyAuthenticationInfo.companyAttachments"
                    control={control}
                  ></Controller>
                );
              })}
            </Grid>
          </Grid>
          <Grid xs={8}>
            <Controller
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  id="LastName"
                  label="法定代表人名称"
                  type="text"
                  fullWidth
                  disabled={
                    company?.verification?.status == 'SUBMITTED' ||
                    company?.verification?.status == 'VERIFIED'
                      ? true
                      : false
                  }
                  error={!!errors.companyAuthenticationInfo?.enterpriseLegalPerson}
                  helperText={
                    !!errors.companyAuthenticationInfo?.enterpriseLegalPerson &&
                    '法定代表人名称不能为空'
                  }
                  {...field}
                />
              )}
              name="companyAuthenticationInfo.enterpriseLegalPerson"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>
          </Grid>
          <Grid xs={8} className={classes.marginBox}>
            <Controller
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  id="LastName"
                  label="手机号"
                  type="text"
                  fullWidth
                  disabled={
                    company?.verification?.status == 'SUBMITTED' ||
                    company?.verification?.status == 'VERIFIED'
                      ? true
                      : false
                  }
                  error={!!errors.companyAuthenticationInfo?.contactPhone}
                  helperText={!!errors.companyAuthenticationInfo?.contactPhone && '手机号不能为空'}
                  {...field}
                />
              )}
              name="companyAuthenticationInfo.contactPhone"
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^1[3-9]\d{9}$/,
                  message: '请输入正确的手机号',
                },
              }}
            ></Controller>
          </Grid>
          <Grid
            xs={8}
            justifyContent="space-between"
            alignItems="center"
            container
            className={classes.marginBox}
          >
            <Controller
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  id="LastName"
                  label="验证码"
                  type="text"
                  style={{
                    width: '80%',
                  }}
                  error={!!errors.companyAuthenticationInfo?.code}
                  helperText={!!errors.companyAuthenticationInfo?.code && '验证码不能为空'}
                  {...field}
                />
              )}
              name="companyAuthenticationInfo.code"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>
            <Button type="submit" variant="contained" color="primary">
              发送验证码
            </Button>
          </Grid>
          <Grid
            xs={8}
            container
            justifyContent="center"
            alignItems="center"
            className={classes.marginBox}
          >
            <Checkbox
              defaultChecked
              color="default"
              inputProps={{ 'aria-label': 'checkbox with default color' }}
            />
            <Typography variant="body2" align="left" color="secondary">
              同意并遵守<span className={classes.agreement}>《FINTEGRITY企业认证服务协议》</span>
            </Typography>
          </Grid>
          <Grid
            xs={8}
            container
            justifyContent="center"
            alignItems="center"
            className={classes.marginBox}
          >
            <Button type="button" variant="contained" color="primary">
              提交
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(InfoView);
