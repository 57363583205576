import React from 'react';

import Layout from '../../layout/CompanyLayout';
import InfoView from '../../views/company/InfoView';

const RegisterPage = (): React.ReactElement => {
  return (
    <Layout>
      <InfoView />
    </Layout>
  );
};

export default RegisterPage;
