export const createCompanyAuthenticationInfo = (): CompanyAuthenticationInfoType => ({
  // 企业名称
  registrationName: '',
  // 企业注册编号
  registrationId: '',
  enterpriseLegalPerson: '',
  contactPhone: '',
  code: '',
  companyAttachments: [],
});
